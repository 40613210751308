/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Avenir LT W01_45 Book1475508";
  display: table;
  width: 100%;
}

html > body {
  height: 100%;
}

#main-container, #bottom-container {
  display: table-row;
  height: 1px;
  overflow: hidden;
}

#main-container {
  height: auto;
}

#error-page {
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}
#error-page #logo {
  padding-top: 25px;
  padding-bottom: 25px;
}
#error-page #logo a {
  display: block;
}
#error-page #logo a img {
  height: 80px;
}
@media only screen and (min-width: 768px) {
  #error-page #logo a img {
    height: 100px;
  }
  #error-page #top-menu {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) {
  #error-page #logo a img {
    height: 130px;
  }
  #error-page #top-menu {
    margin-top: 65px;
  }
}
@media only screen and (min-width: 1200px) {
  #error-page #logo a img {
    height: 150px;
  }
  #error-page #top-menu {
    margin-top: 75px;
  }
}

#global-topbar {
  height: 35px;
  background-image: none;
  background-color: #939393;
}
#global-topbar #contact-info {
  height: 35px;
  line-height: 35px;
  color: #FFFFFF;
  font-weight: 700;
}
#global-topbar #language-switcher {
  padding-right: 25px;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  #global-topbar #language-switcher {
    float: none !important;
  }
}
#global-topbar #language-switcher ul {
  height: 35px;
  line-height: 35px;
}
#global-topbar #language-switcher ul li a:active, #global-topbar #language-switcher ul li a:link, #global-topbar #language-switcher ul li a:visited {
  color: #FFFFFF;
}
#global-topbar #language-switcher ul li a:hover, #global-topbar #language-switcher ul li a.language_item_active {
  color: #053e84;
  text-decoration: none;
}
#global-topbar .btn-mybbf, #global-topbar .btn-bbf-be {
  height: 35px;
  font-weight: 700;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#global-topbar .btn-mybbf {
  background-color: #FFFFFF;
  color: #053e84;
  margin-left: 20px;
}
#global-topbar .btn-mybbf-dropdown {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#global-topbar .btn-bbf-be {
  background-color: #ff6600;
  color: #FFFFFF;
}
#global-topbar a.brand:hover, #global-topbar a.brand:active, #global-topbar a.brand:link, #global-topbar a.brand:visited {
  text-decoration: none;
}
#global-topbar a.brand.bbf-be {
  background-color: #ff6600;
  color: #FFFFFF;
}
#global-topbar a.brand.bbf-mybbf {
  background-color: #FFFFFF;
  color: #053e84;
  margin-left: 20px;
}

#global-header {
  margin-top: 10px;
  margin-bottom: 10px;
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}
#global-header #logo {
  padding-top: 25px;
  padding-bottom: 25px;
}
#global-header #logo a {
  display: block;
}
#global-header #logo a img {
  height: 80px;
}
#global-header #top-menu {
  border: 0 none !important;
  background: inherit !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#global-header #top-menu div.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) {
  #global-header #top-menu {
    float: right !important;
  }
}
#global-header #top-menu-toggle {
  margin: 45px 0 0 0;
  background-color: #053e84 !important;
}
#global-header #top-menu-toggle .icon-bar {
  background-color: #FFFFFF !important;
}
@media only screen and (max-width: 767px) {
  #global-header {
    /*        #top-menu-collapse.collapse {
                display: none;
            }
            
            #top-menu-collapse.collapse.in {
                display: block;
            }*/
  }
}
@media only screen and (min-width: 768px) {
  #global-header #logo a img {
    height: 100px;
  }
  #global-header #top-menu {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) {
  #global-header #logo a img {
    height: 130px;
  }
  #global-header #top-menu {
    margin-top: 65px;
  }
}
@media only screen and (min-width: 1200px) {
  #global-header #logo a img {
    height: 150px;
  }
  #global-header #top-menu {
    margin-top: 75px;
  }
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

#global-member-of {
  background-color: #dde6f3;
  padding-top: 20px;
  padding-bottom: 20px;
}
#global-member-of .header {
  font-size: 2em;
  font-weight: 200;
  color: #053e84;
  text-align: center;
}
#global-member-of .banners {
  text-align: center;
}
#global-member-of .banners a.banner {
  padding: 10px;
  outline-style: none;
}
#global-member-of .banners a.banner:hover {
  text-decoration: none;
}
#global-member-of .banners a.banner img {
  width: 150px;
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

#global-bottom-nav {
  background-color: #89a6cd;
  padding-top: 10px;
  padding-bottom: 10px;
}
#global-bottom-nav #bottom-menu {
  text-align: center;
}
#global-bottom-nav #bottom-menu > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#global-bottom-nav #bottom-menu > ul > li {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
}
#global-bottom-nav #bottom-menu > ul > li > a:link, #global-bottom-nav #bottom-menu > ul > li > a:visited, #global-bottom-nav #bottom-menu > ul > li > a:active {
  color: #FFFFFF;
}
#global-bottom-nav #bottom-menu > ul > li > a:hover {
  color: #F7F7F7;
}

/* Bootstrap 3 overrides */
/* Horizontal allignment */
.hcenter {
  text-align: center;
}

.hright {
  text-align: right;
}

.hleft {
  text-align: left;
}

.bold {
  font-weight: 700;
}

#global-footer {
  background-color: #939393;
  padding-top: 10px;
  padding-bottom: 10px;
}
#global-footer .stay-tuned .header {
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
}
#global-footer .stay-tuned .social-media {
  text-align: center;
}
#global-footer .stay-tuned .social-media a img {
  width: 30px;
}
#global-footer .stay-tuned .panel .panel-body {
  background-color: #dde6f3;
}
#global-footer .address, #global-footer .copyright {
  text-align: center;
  color: #FFFFFF;
}

.page {
  margin-bottom: 20px;
  /* Bootstrap 3 overrides */
  /* Horizontal allignment */
  /* Bootstrap 3 overrides */
  /* Horizontal allignment */
  /* Bootstrap 3 overrides */
  /* Horizontal allignment */
  /* Bootstrap 3 overrides */
  /* Horizontal allignment */
}
.page h1.page-header {
  text-align: center;
  text-transform: uppercase;
  border-bottom: 0 none;
  color: #ff6600;
  margin-top: 0;
}
.page .hcenter {
  text-align: center;
}
.page .hright {
  text-align: right;
}
.page .hleft {
  text-align: left;
}
.page .bold {
  font-weight: 700;
}
.page #tenants-area-top {
  margin-bottom: 20px;
}
.page #tenants-area-middle {
  /* Small Devices, Tablets */
}
.page #tenants-area-middle .banner-position {
  margin-bottom: 20px;
}
.page #tenants-area-middle img.banner, .page #tenants-area-middle a.banner > img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 3px #F7F7F7 solid;
}
@media only screen and (max-width: 767px) {
  .page #tenants-area-middle {
    text-align: center;
  }
  .page #tenants-area-middle img.banner, .page #tenants-area-middle a.banner > img {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
  }
}
.page #tenants-area-middle #banner-position-1 > img.banner, .page #tenants-area-middle #banner-position-1 > a.banner > img, .page #tenants-area-middle #banner-position-2 > img.banner, .page #tenants-area-middle #banner-position-2 > a.banner > img, .page #tenants-area-middle #banner-position-3 > img.banner, .page #tenants-area-middle #banner-position-3 > a.banner > img, .page #tenants-area-middle #banner-position-5 > img.banner, .page #tenants-area-middle #banner-position-5 > a.banner > img, .page #tenants-area-middle #banner-position-6 > img.banner, .page #tenants-area-middle #banner-position-6 > a.banner > img {
  width: 100%;
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}
@media only screen and (min-width: 768px) {
  .page #tenants-area-middle #banner-position-1 > img.banner, .page #tenants-area-middle #banner-position-1 > a.banner > img, .page #tenants-area-middle #banner-position-2 > img.banner, .page #tenants-area-middle #banner-position-2 > a.banner > img, .page #tenants-area-middle #banner-position-3 > img.banner, .page #tenants-area-middle #banner-position-3 > a.banner > img, .page #tenants-area-middle #banner-position-5 > img.banner, .page #tenants-area-middle #banner-position-5 > a.banner > img, .page #tenants-area-middle #banner-position-6 > img.banner, .page #tenants-area-middle #banner-position-6 > a.banner > img {
    height: 76px;
  }
}
@media only screen and (min-width: 992px) {
  .page #tenants-area-middle #banner-position-1 > img.banner, .page #tenants-area-middle #banner-position-1 > a.banner > img, .page #tenants-area-middle #banner-position-2 > img.banner, .page #tenants-area-middle #banner-position-2 > a.banner > img, .page #tenants-area-middle #banner-position-3 > img.banner, .page #tenants-area-middle #banner-position-3 > a.banner > img, .page #tenants-area-middle #banner-position-5 > img.banner, .page #tenants-area-middle #banner-position-5 > a.banner > img, .page #tenants-area-middle #banner-position-6 > img.banner, .page #tenants-area-middle #banner-position-6 > a.banner > img {
    height: 99px;
  }
}
@media only screen and (min-width: 1200px) {
  .page #tenants-area-middle #banner-position-1 > img.banner, .page #tenants-area-middle #banner-position-1 > a.banner > img, .page #tenants-area-middle #banner-position-2 > img.banner, .page #tenants-area-middle #banner-position-2 > a.banner > img, .page #tenants-area-middle #banner-position-3 > img.banner, .page #tenants-area-middle #banner-position-3 > a.banner > img, .page #tenants-area-middle #banner-position-5 > img.banner, .page #tenants-area-middle #banner-position-5 > a.banner > img, .page #tenants-area-middle #banner-position-6 > img.banner, .page #tenants-area-middle #banner-position-6 > a.banner > img {
    height: 120px;
  }
}
.page #tenants-area-middle #banner-position-4 > img.banner, .page #tenants-area-middle #banner-position-4 > a.banner > img {
  width: 100%;
  /* Small Devices, Tablets */
  /* Medium Devices, Desktops */
  /* Large Devices, Wide Screens */
}
@media only screen and (min-width: 768px) {
  .page #tenants-area-middle #banner-position-4 > img.banner, .page #tenants-area-middle #banner-position-4 > a.banner > img {
    height: 268px;
  }
}
@media only screen and (min-width: 992px) {
  .page #tenants-area-middle #banner-position-4 > img.banner, .page #tenants-area-middle #banner-position-4 > a.banner > img {
    height: 337px;
  }
}
@media only screen and (min-width: 1200px) {
  .page #tenants-area-middle #banner-position-4 > img.banner, .page #tenants-area-middle #banner-position-4 > a.banner > img {
    height: 400px;
  }
}
.page #tenants-area-bottom {
  border-top: 1px #F7F7F7 solid;
  padding-top: 20px;
}
.page #tenants-area-bottom #download-mybbf-app {
  text-align: center;
}
.page #tenants-area-bottom #download-mybbf-app > h1 {
  color: #053e84;
  margin-bottom: 20px;
}
.page #tenants-area-bottom #download-mybbf-app > img.bbf-app-image {
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.page #tenants-area-bottom #download-mybbf-app > p.text-1 {
  text-transform: uppercase;
  font-weight: 700;
  color: #ff6600;
}
.page #tenants-area-bottom #download-mybbf-app > p.text-2 {
  color: #053e84;
}
.page #tenants-area-bottom #download-mybbf-app > .bbf-app-links img {
  width: 140px;
  margin-bottom: 10px;
}
.page #tenants-area-bottom .image-container.center {
  text-align: center;
}
.page #tenants-area-bottom .image-container.center > img {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
}
.page .hcenter {
  text-align: center;
}
.page .hright {
  text-align: right;
}
.page .hleft {
  text-align: left;
}
.page .bold {
  font-weight: 700;
}
.page .rent-payment-area > h2 {
  text-align: center;
  margin-bottom: 40px;
}
.page .rent-payment-area form {
  margin-bottom: 20px;
}
.page .rent-payment-area .card-vendors {
  text-align: center;
}
.page .rent-payment-area label.card-vendor {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  margin: 20px;
  cursor: pointer;
}
.page .rent-payment-area label.card-vendor > input[type="radio"] {
  display: block;
  margin: 0.5em auto;
}
.page .rent-payment-area .buttons-container {
  text-align: center;
}
.page .rent-payment-area .buttons-container input[type="submit"] {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
}
.page .hcenter {
  text-align: center;
}
.page .hright {
  text-align: right;
}
.page .hleft {
  text-align: left;
}
.page .bold {
  font-weight: 700;
}
.page .technical-assistance-area h2 {
  text-align: center;
  margin-bottom: 2em;
}
.page .hcenter {
  text-align: center;
}
.page .hright {
  text-align: right;
}
.page .hleft {
  text-align: left;
}
.page .bold {
  font-weight: 700;
}
.page #task-history-track-container {
  margin-top: 20px;
  /* iPhone 5 in portrait */
}
.page #task-history-track-container .comment {
  margin-bottom: 1em;
}
.page #task-history-track-container .comment .image, .page #task-history-track-container .comment .message {
  float: left;
  display: block;
  position: relative;
}
.page #task-history-track-container .comment .image {
  width: 25%;
  min-height: 1px;
}
.page #task-history-track-container .comment .image > div {
  margin-right: 5%;
}
.page #task-history-track-container .comment .image > div img {
  display: block;
  width: 100%;
  border-radius: 100%;
  border-radius: 100%;
  border-radius: 100%;
}
.page #task-history-track-container .comment .message {
  width: 75%;
}
.page #task-history-track-container .comment .message > div {
  margin-left: 5%;
  min-height: 100px;
  padding: 0.75em;
  background-color: #F7F7F7;
  border-radius: 0.75em;
  border-radius: 0.75em;
  border-radius: 0.75em;
}
.page #task-history-track-container .comment .message .message-header {
  border-top: 1px #dde6f3 solid;
  padding-top: 0.75em;
  font-size: 0.75em;
  font-weight: 700;
  padding-bottom: 0.75em;
}
.page #task-history-track-container .comment .message .message-header .author {
  color: #053e84;
  float: left;
}
.page #task-history-track-container .comment .message .message-header .creation-date {
  color: #053e84;
  float: right;
  text-align: right;
}
.page #task-history-track-container .comment .message .message-header:first-child {
  border: 0 none;
  padding-top: 0;
}
.page #task-history-track-container .comment .message .message-header:after {
  content: "";
  display: table;
  clear: both;
}
.page #task-history-track-container .comment .message .message-content {
  color: #222222;
  padding-bottom: 0.75em;
}
.page #task-history-track-container .comment:after {
  content: "";
  display: table;
  clear: both;
}
.page #task-history-track-container .comment.odd .message > div:after, .page #task-history-track-container .comment.odd .message > div:before {
  right: 95%;
  top: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.page #task-history-track-container .comment.odd .message > div:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #F7F7F7;
  border-width: 1em;
  margin-top: -1em;
}
.page #task-history-track-container .comment.odd .message > div:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #F7F7F7;
  border-width: 1em;
  margin-top: -1em;
}
.page #task-history-track-container .comment.even .image {
  float: right;
}
.page #task-history-track-container .comment.even .image > div {
  margin-left: 5%;
  margin-right: 0;
}
.page #task-history-track-container .comment.even .message > div {
  margin-left: 0;
  margin-right: 5%;
}
.page #task-history-track-container .comment.even .message > div:after, .page #task-history-track-container .comment.even .message > div:before {
  left: 95%;
  top: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.page #task-history-track-container .comment.even .message > div:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #F7F7F7;
  border-width: 1em;
  margin-top: -1em;
}
.page #task-history-track-container .comment.even .message > div:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #F7F7F7;
  border-width: 1em;
  margin-top: -1em;
}
@media only screen and (min-device-width: 1025px) {
  .page #task-history-track-container .comment .image {
    width: 10%;
  }
  .page #task-history-track-container .comment .message {
    width: 90%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .page #task-history-track-container .comment.odd .message > div:after, .page #task-history-track-container .comment.odd .message > div:before,
  .page #task-history-track-container .comment.even .message > div:after,
  .page #task-history-track-container .comment.even .message > div:before {
    border-width: 0.75em;
    margin-top: -0.75em;
  }
}
